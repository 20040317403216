<template>
  <div class="pr-3">
    <p class="text-white">
      Olá<span v-if="username">, {{ username }}</span>!
    </p>
    <p class="text-white">
      Bem vindo ao Hub de Votacões. Aqui você poderá ver seus resultados e principais informações.
    </p>
    <vs-table
      v-if="votingProjs"
      stripe
      class="pb-4"
      :data="votingProjs"
      no-data-text="Nenhum evento disponível"
    >
      <template slot="header">
        <div
          class="d-flex justify-content-between align-items-center w-100 my-3"
        >
          <h3 class="text-dark ml-4">
            Lista de Votações
          </h3>
        </div>
      </template>
      <template slot="thead">
        <vs-th>Nome</vs-th>
        <vs-th>Data Início</vs-th>
        <vs-th>Data Final</vs-th>
        <vs-th>Status</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          v-for="(proj, i) in data"
          :key="i"
          class="border-top"
        >
          <vs-td class="py-1">
            {{ proj.votationName }}
            <span v-if="checkItemAccess('Dev')">
              ({{ proj.surveyId }})
            </span>
          </vs-td>
          <vs-td class="py-1">
            <p class="mb-0">
              <b>Dia:</b> {{ proj.votationInitDate }}
            </p>
            <p
              v-if="proj.votationInitTime"
              class="mb-0"
            >
              <b>Hora: </b>
              {{ proj.votationInitTime }}h
            </p>
          </vs-td>
          <vs-td class="py-1">
            <p class="mb-0">
              <b>Dia:</b> {{ proj.votationEndDate }}
            </p>
            <p
              v-if="proj.votationEndTime"
              class="mb-0"
            >
              <b>Hora: </b>
              {{ proj.votationEndTime }}h
            </p>
          </vs-td>
          <vs-td class="py-1 text-center">
            <span v-if="Date.now() < convertDate(proj.votationInitDate, proj.votationInitTime)">
              <i class="fas fa-calendar-check mr-2" />A iniciar
            </span>
            <span v-if="Date.now() > convertDate(proj.votationInitDate, proj.votationInitTime) && Date.now() < convertDate(proj.votationEndDate, proj.votationEndTime)">
              <i class="fas fa-star mr-2" />Iniciado
            </span>
            <span v-if="Date.now() > convertDate(proj.votationEndDate, proj.votationEndTime)">
              <i class="fas fa-check mr-2" />Concluído
            </span>
          </vs-td>
          <vs-td class="py-1 text-center">
            <vs-button
              class="ml-auto btn btn bg-transparent border-0 btn btn-primary text-light"
              title="Visualizar campanha"
              @click="goToVotingView(proj.votationCode)"
            >
              <i class="fas fa-chart-pie mr-2" /> Visualizar
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
/* Helpers */
import moment from 'moment';

/* Services */
import Voting from '@/services/voting';

const voting = new Voting();

export default {
  name: 'Votacoes',
  components: {},
  data: () => ({
    title: 'Votações',
    obj: {},
    eventDialog: false,
    editingEvent: false,
    votingProjs: [],
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    cor1() {
      return this.$store.state.configBox.cor1;
    },
    cor2() {
      return this.$store.state.configBox.cor2;
    },
    username() {
      if (this.$store.state.loginData.identity) {
        return this.$store.state.loginData.identity.name1 || '';
      }
      return ''
    },
  },
  mounted() {
    this.getVotingProjs();
  },
  methods: {
    checkItemAccess(access) {
      if (!access) return true;
      return this.$store.state.loginData.accessValues.includes(access)
    },
    getVotingProjs() {
      this.$store.dispatch('set_isLoadingActive', true);
      voting.getVotingProjs(this.ownerId).then(resp => {
        this.votingProjs = resp
        this.$store.dispatch('set_isLoadingActive', false);
      })
    },
    goToVotingView(votationCode) {
      this.$router.push({ path: '/votacoes/detalhes/' + votationCode });
    },
    dateFormated(date) {
      if (!date) return 'Não informado';
      return moment(date).format('DD/MM/yyyy');
    },
    timeFormated(time) {
      if (!time) return 'Não informado';
      return moment(time)
        .locale('pt')
        .format('LT');
    },
    convertDate(date, time) {
      const day = date.split('/')[0]
      const month = date.split('/')[1]
      const year = date.split('/')[2]
      const hour = time.split(':')[0]
      const minute = time.split(':')[1]
      const result = new Date(year, month-1, day, hour, minute).getTime()
      return result
    }
  },
};
</script>

<style></style>
